import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'; 
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators'
import { AddUser, UpdateUser} from 'src/app/models/addUserModel';
import { RideDetails} from 'src/app/models/suzukiModel';
import { RideDetailsObj} from 'src/app/models/suzukiModelObj';
import { Users} from 'src/app/models/usersModel';
import { DashboardData} from '../models/dashboardData';
import { RideDetailsNew} from 'src/app/models/suzukiModelNew';

@Injectable({
  providedIn: 'root'
})
export class SuzukiService {
  public title = new BehaviorSubject('Title');
  addUser: AddUser;
  
  constructor(private http: HttpClient,  private auth: AuthService) {}

setHeaderTitle(title) {
    this.title.next(title);
  }

  GetRideDetails(): Observable<RideDetails> {
    return this.http.get(`${environment.suzukiApiUrl}/ride-details`).
    pipe(map((res) =>{
      const rideDetails:RideDetails = res as RideDetails;
      if(rideDetails != null){
       return rideDetails;
      }
      return null;
    }));
  }

  GetRideDetailsNew(): Observable<RideDetailsNew> {
    return this.http.get(`${environment.suzukiApiUrl}/ride-details`).
    pipe(map((res) =>{
      const rideDetails:RideDetailsNew = res as RideDetailsNew;
      if(rideDetails != null){
       return rideDetails;
      }
      return null;
    }));
  }

  GetUserList(): Observable<Users> {
    return this.http.get(`${environment.suzukiApiUrl}/accounts`).
    pipe(map((res) =>{
      const users:Users = res as Users;
      if(users != null){
       return users;
      }
      return null;
    }));
  }

  addNewUser(addUser: Object): Observable<Object> {
    let data = [];
    data.push(addUser);
    return this.http.post(`${environment.suzukiApiUrl}/send-invite`, data);
  }

  addNewAdmin(addUser: Object): Observable<Object> {
    return this.http.post(`${environment.suzukiApiUrl}/add-admin`, addUser);
  }

  updateUser(updateUser: UpdateUser): Observable<UpdateUser[]> {
    return this.http.put<any>(`${environment.suzukiApiUrl}/accounts/`+ updateUser.id, updateUser);
  }

 getUserBasedOnID(id: string): Observable<any> {
    return this.http.get(`${environment.suzukiApiUrl}/accounts/${id}`);
  }

  TestRideDetailsById(id: string): Observable<any> {
    return this.http.get(`${environment.suzukiApiUrl}/ride-details/${id}`)
     .pipe(map((res) =>{
      const rideDetailsObj:RideDetailsObj = res as RideDetailsObj;
      if(rideDetailsObj != null){
       return rideDetailsObj;
      }
      return null;
    }));  
  }

  AddComment(id: string, addComment: Object): Observable<any> {
    return this.http.put(`${environment.suzukiApiUrl}/ride-details/${id}`, addComment)
  }
  
 DeleteComment(id: string, dashboardData: DashboardData){
    return  this.http.put(`${environment.suzukiApiUrl}/ride-details/${id}`, dashboardData);
  } 
  
 
  DeleteUser(delid: string){
    return this.http.delete(`${environment.suzukiApiUrl}/accounts/${delid}`);
  }

  UpdatePassword(accountId: string, updatePassword: Object): Observable<Object> {
    return this.http.put(`${environment.suzukiApiUrl}/accounts/${accountId}`, updatePassword);
  }

  }

  

