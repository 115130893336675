import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorize',
  templateUrl: './unauthorize.component.html',
  styleUrls: ['./unauthorize.component.css']
})
export class UnauthorizeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
