import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard, UserGuard } from './auth/admin.guard';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { SendResetPasswordComponent } from './send-reset-password/send-reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'admin', loadChildren: './marineMotors/admin/admin.module#AdminModule', canActivate: [AdminGuard] },
  { path: 'user', loadChildren: './marineMotors/user/user.module#UserModule', canActivate: [UserGuard] },
  {path:'send-reset-password', component: SendResetPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'unauthorize', component: UnauthorizeComponent },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routerComponents = [NotfoundComponent];


