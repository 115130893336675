import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassword } from 'src/app/models/addUserModel';
import { NgForm } from '@angular/forms';
import { first } from "rxjs/operators";
import 'rxjs/add/operator/filter'
import { MessageService } from 'primeng';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  errorMsg: string;
  successMsg: string;
  resetToken: null;
  CurrentState: any;
  IsResetFormValid = true;

  resetPass: ResetPassword = new ResetPassword();
  //  resetPass: ResetPassword;
  submitted = false;
  token: string;
  blocked_Login_Wait: boolean = false;
  confirmPassword: string;
  password: string;

  constructor(private authService: AuthService, private router: Router,
    private fb: FormBuilder, private activatedRoute: ActivatedRoute, private messageService: MessageService) {
  }

  ngOnInit() {
    this.resetFrm();
    // this.token = this.activatedRoute.snapshot.params['token'];
    // console.log("Token: " + this.token);
    this.activatedRoute.queryParams
      .filter(params => params.token)
      .subscribe(params => {
        this.token = params.token;
       // console.log("ParaTokenms: " + this.token);
      });

  }

  resetFrm() {
    this.resetForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
      }
    );
  }

  Validate(passFormGroup: FormGroup) {
    const newPassword = passFormGroup.controls.newPassword.value;
    const confirmPassword = passFormGroup.controls.confirmPassword.value;

    if (confirmPassword.length <= 0) {
      return null;
    }

    if (confirmPassword !== newPassword) {
      return {
        doesNotMatch: true
      };
    }

    return null;
  }

  onSubmit(resetFrm: NgForm) {
    if (resetFrm.valid) {
      this.blocked_Login_Wait = true;
      this.authService.ResetPassword(resetFrm.value)
        .pipe(first())
        .subscribe(
          data => {
            this.blocked_Login_Wait = false;
            this.router.navigate(['/login']);
            if (data) {
              this.messageService.add({ key: 'usersKey', severity: 'success', summary: 'Success', detail: 'Password reset Successfully' });
            }
          },
          error => {
            this.blocked_Login_Wait = false;
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}`;
          }
          window.alert(errorMessage);

          });
    }
  }

}
