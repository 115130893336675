import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate() {
    return this.canLoad();
  }
  canLoad() {
    if (!this.authService.isLoggedInUser()) {
      this.router.navigate(['admin/dashboard']);
    }
    return this.authService.isLoggedInUser();
  }
}

@Injectable()
export class UserGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate() {
    return this.canLoad();
  }
  canLoad() {
    if (!this.authService.isLoggedInUser()) {
      this.router.navigate(['user/dashboard']);
    }
    return this.authService.isLoggedInUser();
  }
}