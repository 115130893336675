export class AddUser {
    email:           string;
    firstName:       string;
    lastName:        string;
    appAccess:       string;
    dashboardAccess: string;
    departmentName: string;
}

export class UpdateUser {
    id:         string;
    firstName:  string;
    lastName:   string;
    email:      string;
    departmentName: string;
    role:       string;
   }

export class AddComment {
    _id:      string;
    comment?: string;
    created:  Date;
    updated:  Date;
    remark?:  string;
}

export class ForgotPassword {
    email: string;
}  

export class ResetPassword {
    token: string;
    password:   string;
    confirmPassword: string;
}  

export class AddAdmin {
    email:           string;
    firstName:       string;
    lastName:        string;
    departmentName: string;
    role: string;
}

export class UpdatePassword {
    email:           string;
    password:       string;
    confirmPassword:   string;
    oldPassword:       string;
}

