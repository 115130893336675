import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SuzukiService } from '../../services/suzuki.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  getRole:string;
  getName:string;
  constructor(private suzukiService: SuzukiService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.getRole = this.authService.getRole();
    this.getName = this.authService.getName();
  }

  signout() {
    this.authService.signout();
     this.router.navigate(['/login']);
   } 

}
