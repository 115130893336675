import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginFrm: FormGroup;
  submitted = false;
  blocked_Login_Wait: boolean = false;
  token: string;
  admin = 'Admin';

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router,
    private messageService: MessageService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params['token'];
    this.loginFrm = this.formBuilder.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });
  }

  get f() {
    return this.loginFrm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginFrm.invalid) {
      return;
    }
    this.blocked_Login_Wait = true;
    const { email, password, rememberMe } = this.loginFrm.value;
    this.authService.loginUser(
      {
        email: this.f.email.value,
        password: this.f.password.value
      })
      .subscribe(success => {
        this.blocked_Login_Wait = false;
        if (success) {
          this.messageService.add({ key: 'loginKey', severity: 'success', summary: 'Success', detail: 'Login Successfully' });
          if (success['role'] == 'Admin') {
            this.router.navigate(['/admin/dashboard']);
          }
          else if (success['role'] == 'User') {
            this.router.navigate(['/user/dashboard']);
          }
          else {
            this.router.navigate(['/login'])
          }
        }
      });
  }

}