import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { NgForm } from '@angular/forms';
import { first } from "rxjs/operators";
import 'rxjs/add/operator/filter';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-send-reset-password',
  templateUrl: './send-reset-password.component.html',
  styleUrls: ['./send-reset-password.component.css']
})
export class SendResetPasswordComponent implements OnInit {

  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  blocked_Login_Wait: boolean = false;

  constructor(private authService: AuthService, private router: Router, private messageService: MessageService) { }

  ngOnInit() {
     this.RequestResetForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email], this.forbiddenEmails),
    });
  }


  onSubmit(resetFrm: NgForm) {
    if (resetFrm.valid) {
      this.blocked_Login_Wait = true;
      this.authService.ForgotPassword(resetFrm.value)
        .pipe(first())
        .subscribe(
          data => {
            this.blocked_Login_Wait = false;
            this.router.navigate(['/login']);
            if (data) {
              this.messageService.add({ key: 'usersKey', severity: 'success', summary: 'Success', detail: 'Please check your mail for reset password.' });
            }
          },
          error => {
            this.blocked_Login_Wait = false;
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}`;
          }
          window.alert(errorMessage);

          });
    }
  }
}
