import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { SharedModule } from './shared/shared.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; 
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuzukiService} from './services/suzuki.service';
import { RouterModule } from '@angular/router';
import { TokenInterceptor } from './auth/token.interceptor'; 
import { ErrorInterceptor } from './auth/error.interceptor'; 
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { AdminGuard, UserGuard} from './auth/admin.guard';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService} from 'primeng/api';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToastModule } from 'primeng/toast';
import { Routes} from '@angular/router';
const routes: Routes = [];
import { SendResetPasswordComponent } from './send-reset-password/send-reset-password.component';

@NgModule({
  declarations: [ 
    AppComponent, LoginComponent,NotfoundComponent,
    UnauthorizeComponent, ResetPasswordComponent, SendResetPasswordComponent
  ],
  
 imports: [BrowserAnimationsModule,
    BrowserModule, AppRoutingModule, SharedModule,
    AngularFontAwesomeModule, 
    HttpClientModule,
    FormsModule, TableModule, MultiSelectModule, 
     ReactiveFormsModule, RouterModule,
    NgbModule.forRoot(),
    ProgressBarModule, ConfirmDialogModule, ToastModule, 
    //For 404 page refresh
   // RouterModule.forRoot(routes, { useHash: true })
    ], 
  providers: [SuzukiService,  NgbActiveModal, AuthGuard, AdminGuard, UserGuard,  ConfirmationService, 
    MessageService, AuthService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
   { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
